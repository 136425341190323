<template>
 <div class="bg-gray-100 ">
  <nav class="bg-white-cream px-6 relative shadow-md">
    <div class="flex flex-row justify-between items-center py-2">
       <div>
          <a href="/">
            <img class="h-16 w-auto sm:h-20" src="@/assets/logo.jpg"
          /></a>
        </div>
      <div class="group flex flex-col items-center">
        <button  @click="showMenu = !showMenu" class="p-2 rounded-lg md:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-10 fill-current" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" /></svg>
        </button>
        <div class="hidden group-hover:block md:block absolute md:static bg-white-cream inset-x-0 top-16 py-3 shadow-md md:shadow-none text-gray-600">
          <div class="flex flex-row justify-center items-center text-center font-semibold text-gray-500">
            <a :class="[routeName == 'Home' ? 'active-link' : '']"  class="active px-6 py-1 flex flex-col md:flex-row md:items-center" href="/"> 
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="fill-current h-10 md:h-5 px-2" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/><path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/></svg>
              Home 
            </a>
            <a :class="[routeName == 'Student' ? 'active-link' : '']" class="px-6 py-1 flex flex-col md:flex-row md:items-center" href="/be-a-student"> 
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="fill-current h-10 md:h-5 px-2" viewBox="0 0 16 16"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/></svg>
              Get A Tutor
            </a>
            <a :class="[routeName == 'Tutor' ? 'active-link' : '']"  class="px-6 py-1 flex flex-col md:flex-row md:items-center" href="/be-a-tutor"> 
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="fill-current h-10 md:h-5 px-2" viewBox="0 0 16 16"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/></svg>
              Become A Tutor
            </a>
            <a :class="[routeName == 'Contact' ? 'active-link' : '']" class="px-6 py-1 flex flex-col md:flex-row md:items-center" href="/contact-us"> 
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="fill-current h-10 md:h-5 px-2" viewBox="0 0 16 16"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/></svg>
              Contact Us
            </a>
            <a :class="[routeName == 'Login' ? 'active-link' : '']" class="px-6 py-1 flex flex-col md:flex-row md:items-center" href="/auth/login"> 
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="fill-current h-10 md:h-5 px-2" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/><path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/></svg>
              Login 
            </a>
          </div>
        </div>
      </div>
    </div>
     <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <ul
          :class="{'hidden':showMenu}"
          class="
            md:hidden
            flex-col
            mt-8
            space-y-4
            md:flex
            md:space-y-0
            md:flex-row
            md:items-center
            md:space-x-10
            md:mt-0
            mb-5
            pb-3
          "
        >
          <li class="text-sm font-bold text-gray-800 hover:text-blue-400">
            <a href="/be-a-student">Get A Tutor</a>
          </li>
          <li class="text-sm font-bold text-gray-800 hover:text-blue-400">
            <a href="/be-a-tutor">Become A Tutor</a>
          </li>
          <li class="text-sm font-bold text-gray-800 hover:text-blue-400">
            <a href="/contact-us"> Contact Us</a>
          </li>
          <li class="text-sm font-bold text-gray-800 hover:text-blue-400">
            <a href="/auth/login">Login</a>
          </li>
        </ul>
  </nav>


</div>
</template>
<script>

export default {
   data() {
    return {
      routeName:'',
      showMenu: true,
    };
  },
  created() {
    this.routeName = this.$route.name;
    console.log("route name", this.$route.name);
  },
};
</script>

<style scoped>
/* unvisited link */
a:link {
  color: #323232;
}

/* visited link */
a:visited {
  color: #323232;
}

/* mouse over link */
a:hover {
  color: #F7A300;
}

/* selected link */
a:active {
  color: #008e89;
}
.active-link{
  color: #008e89 !important;
}
</style>
