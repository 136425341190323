<template>
  <v-row justify="center">
    <v-dialog v-model="successDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5" justify="space-around">
          <v-icon x-large color="green">mdi-check-bold</v-icon>
        </v-card-title>
        <v-card-text
          >
          <p class="text-h5"><b>success</b></p>
           Check out for an email from our team.
           One of our specialist will be in touch to chat through pricing and tutor options, and recommend a strategy going forward. <b>If you dont see any email from us, please check your spam folder</b>.
          </v-card-text
        >
        <v-card-actions>
          
          <v-btn color="green darken-1" text @click="close()">
           Go To Home Page
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["successDialog"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.$emit("close_dialog", false);
    },
  },
};
</script>